/* Mixins */
.energy-config-component .page-body {
  background-color: #fff;
  padding: 20px;
}
.energy-config-component .page-body .jtl-button-component {
  margin-left: 125px;
}
.energy-config-component .page-body .card-item {
  display: inline-block;
  width: 500px;
  height: 300px;
  margin: 20px 20px 0 0;
  vertical-align: top;
}
.energy-config-component .page-body .card-item .select-device-attribute .ant-select {
  width: 100%;
}